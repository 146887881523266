.pagination {
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  color: var(--black);
  font-weight: 500;
}
.pagination .pagination-dots {
  padding: 0.5rem 0.65rem;
}
.pagination .pagination-num {
  cursor: pointer;
  /* padding: 0.5rem 0.65rem; */
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--black);
  color: var(--white);
  border-radius: 0.5rem;
}
.pagination .pagination-num.active {
  background-color: var(--yellow);
  color: var(--darkblack);
}
.pagination .pagination-btn {
  color: var(--white);
}
.pagination .disabled.pagination-btn {
  color: var(--black);
}