.text-editor {
  border: solid 1px var(--black);
}
.text-editor * {
  font-family: "Roboto", sans-serif !important;
}
#toolbar {
  padding: 0.25rem 0.5rem;
}
.ql-formats {
  border-right: solid 1px var(--black);
  padding: 0 0.25rem;
}
.ql-formats:last-child {
  border-right: none;
}
.ql-formats button {
  padding: 4px;
  transform: translate(0px, 0.15rem);
  transition: all 0.2s ease-in-out;
}
.ql-formats button:hover,
.ql-active {
  /* equivalent to color: #4071b8; */
  filter: invert(46%) sepia(9%) saturate(3649%) hue-rotate(176deg) brightness(87%) contrast(86%);
}
.ql-clean {
  transform: scale(0.8) translate(0px, 3px) !important;
}

.text-editor-body {
  border-top: 1px solid var(--black);
  word-wrap: break-word;
  font-weight: 400;
  color: var(--white);
  height: 20rem;
}
/* Identical style for this and the package page content */
.package-content,
.ql-editor {
  word-break: break-all;
}
.package-content h1,
.package-content h2,
.package-content h3,
.package-content h4,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4 {
  font-weight: 500;
}
.package-content h1,
.ql-editor h1 {
  font-size: 24px !important;
  line-height: 1.4;
}
.package-content h2,
.ql-editor h2 {
  font-size: 22px !important;
  line-height: 1.3;
}
.package-content h3,
.ql-editor h3 {
  font-size: 20px !important;
  line-height: 1.2;
}
.package-content h4,
.ql-editor h4 {
  font-size: 18px !important;
  line-height: 1.1;
}
.package-content a,
.ql-editor a {
  color: var(--darkblue2);
  text-decoration: underline;
  cursor: pointer;
}
.package-content ul,
.ql-editor ul {
  list-style: disc;
  margin-left: 20px !important;
  padding: 0 !important;
}
.package-content ol,
.ql-editor ol {
  list-style: decimal;
  margin-left: 20px !important;
  padding: 0 !important;
}
.package-content li,
.ql-editor li {
  padding: 0 !important;
}
