@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

:root {
  --darkblack: #0c0c0c;
  --darkblack2: #0f0f0f;
  --dark: #1a1a1a;
  --dark2: #121212;
  --black: #333;
  --white: #fafafa;
  --lightgrey: #ebebeb;
  --lightgrey2: #c4c4c4;
  --lightgrey3: #d4d4d4;
  --yellow: #fdee30;
  --yellow2: #ffd700;
}

body {
  color: var(--white);
  background: var(--darkblack);
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
h1 {
  font-size: 2.5rem;
  line-height: 2.75rem;
}
h2 {
  font-size: 2rem;
  line-height: 2.25rem;
}
h3 {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
h5 {
  font-size: 1rem;
  line-height: 1.25rem;
}
h6 {
  font-size: 0.875rem;
  line-height: 1.15rem;
}

input,
select,
textarea {
  padding: 0.5rem 1rem;
  border: solid 1px var(--black);
  border-radius: 0.5rem;
  background: transparent;
  transition: all 0.2s ease-in-out;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: solid 1px var(--yellow);
}
input::placeholder,
select::placeholder,
textarea::placeholder {
  color: var(--lightgrey2);
}
select option {
  background: var(--darkblack);
}