.dashboard-item {
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
  width: 12rem;
}
.dashboard-item label {
  padding: 0.5rem;
  border-radius: 0.25rem;
  transform: scale(1.2);
  position: absolute;
  top: -0.75rem;
  left: 1.5rem;
}
.dashboard-item:nth-child(2n) {
  background-color: var(--yellow);
  color: var(--darkblack);
}
.dashboard-item:nth-child(2n) label {
  background-color: var(--dark);
  color: var(--white);
}
.dashboard-item:nth-child(2n + 1) {
  background-color: var(--dark);
  color: var(--white);
}
.dashboard-item:nth-child(2n + 1) label {
  background-color: var(--yellow);
  color: var(--darkblack);
}