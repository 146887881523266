.sidebar {
  color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15rem;
  height: 100vh;
  overflow-y: auto;
  border-right: 1px solid var(--black);
}
.sidebar .sidebar-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0.75rem 0;
  padding: 0.75rem 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}
.sidebar .sidebar-item.active {
  background: var(--yellow);
  color: var(--darkblack);
}
.sidebar .sidebar-item:not(.active):hover {
  background: var(--black);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  margin: 2rem;
  margin-left: 17rem;
}
header .header-tabs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--dark);
  border: 1px solid var(--dark);
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
header .header-tabs svg {
  color: var(--yellow);
}
header .header-tabs:hover {
  color: var(--yellow);
}

.page-layout {
  margin: 2rem;
  margin-left: 17rem;
  min-height: calc(100vh - 8rem);
  transition: all 0.2s ease-in-out;
}


/* @media screen and (max-width: 880px) {
  header {
    margin-left: 2rem;
  }
  .sidebar.lg {
    display: none;
  }
  .page-layout {
    margin-left: 2rem;
  }
} */