.auth-card {
  border: solid 1px var(--lightgrey2);
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
