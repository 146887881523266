.btn {
  text-align: center;
  transition: 0.3s all ease-in-out;
}
.btn.btn-yellow {
  background-color: var(--yellow);
  color: var(--darkblack);
  font-weight: 600;
}
.btn.btn-yellow:hover {
  background-color: var(--yellow2);
}
.btn.btn-dark {
  background-color: var(--dark);
  color: var(--white);
  border: solid 1px var(--black);
}
.btn.btn-dark:hover {
  border-color: var(--yellow);
}
.btn.btn-dark-noborder {
  background-color: var(--dark);
  color: var(--white);
}
.btn.btn-md {
  padding: 0.5rem 1rem;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.88rem;
}
.btn.btn-rounded-md {
  border-radius: 0.5rem;
}
.btn.btn-rounded-sm {
  border-radius: 0.25rem;
}