::backdrop {
  background-image: var(--black);
}
dialog {
  padding: 0.5rem;
  border: solid 1px var(--yellow);
  border-radius: 0.75rem;
  background-color: var(--darkblack);
  min-width: 20rem;
}
dialog:focus {
  outline: none;
}
